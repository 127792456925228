export const WEBSITE_MODE = {
  DARK: "DARK",
  LIGHT: "LIGHT",
};

export const EVENT_TYPES = {
  OFFLINE: "VENUE",
  ONLINE: "ONLINE",
};

export const ALLOWED_VIDEO_EXT = ["mp4", "mov"];
