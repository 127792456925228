/* eslint-disable import/prefer-default-export */
import styled, { css } from "styled-components";
import Image from "next/image";

const AvatarSizes = [
  {
    size: "base",
    px: "45px",
    font: "1.1em",
  },
  {
    size: "small",
    px: "25px",
    font: "0.6em",
  },
  {
    size: "medium",
    px: "32px",
    font: "0.8em",
  },
  {
    size: "large",
    px: "80px",
    font: "2.5em",
  },
  {
    size: "extraLarge",
    px: "100px",
    font: "3em",
  },
];

const setAvatarSize = (propSize) => {
  if (propSize == null) return AvatarSizes[0];
  return AvatarSizes.filter((s) => s.size === propSize)[0];
};

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: ${(props) =>
    props.bgColor || "var(--background-secondary-color)"};
  border: 2px solid var(--brand-color);
  border-radius: 500rem;
  color: ${(props) => props.color || "var(--text-secondary-color)"};
  height: ${(props) => setAvatarSize(props.size).px};
  width: ${(props) => setAvatarSize(props.size).px};

  &::before {
    content: ${(props) =>
      `"${props.firstInitial || ""}${props.lastInitial || ""}"`};
    color: var(--text-light-color);
    font-weight: bold;
    font-size: ${(props) => setAvatarSize(props.size).font};
  }

  ${(props) =>
    props.imageUrl &&
    css`
      background-image: url("${props.imageUrl}");
      background-size: cover;
      background-position: center;
      &::before {
        content: "";
      }
    `}

  ${(props) =>
    props.movedUp &&
    css`
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    `}

  ion-icon {
    font-size: ${(props) => setAvatarSize(props.size).font};
  }
`;

export const Img = styled(Image)`
  display: block;
  width: 100%;
  border-radius: 50%;
  height: 100%;
  object-fit: cover;
  margin: 0 auto;
  z-index: 2;
`;
