import { memo } from "react";
import PropTypes from "prop-types";
import Image from "next/image";

import { Wrapper } from "./styles";
import { httpsUrl } from "../../helpers/data_helpers";

const Avatar = ({ user, size, imageUrl, bgColor, color, movedUp }) => {
  const { firstName, lastName } = user || {};
  return (
    <Wrapper
      size={size}
      firstInitial={firstName && firstName.charAt(0)}
      lastInitial={lastName && lastName.charAt(0)}
      imageUrl={httpsUrl(imageUrl)}
      bgColor={bgColor}
      color={color}
      movedUp={movedUp}
    >
      {imageUrl && (
        <Image
          width="80"
          height="80"
          src={httpsUrl(imageUrl)}
          alt="Profile picture"
        />
      )}
    </Wrapper>
  );
};

Avatar.defaultProps = {
  user: null,
  size: "large",
  imageUrl: undefined,
  bgColor: "var(--background-secondary-color)",
  color: "var(--text-secondary-color)",
  movedUp: false,
};

Avatar.propTypes = {
  user: PropTypes.shape(),
  size: PropTypes.oneOf(["base", "small", "medium", "large", "extraLarge"]),
  imageUrl: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  movedUp: PropTypes.bool,
};

export default memo(Avatar);
