import { memo, useEffect, useState } from "react";
import Link from "next/link";
import PropTypes from "prop-types";
import Avatar from "../../components/Avatar";
import { Button } from "../../components/LinkItem/styles";
import {
  LPTitle,
  LPText,
  PreneurTypeText,
  HeroWrapper,
  SectionWrapper,
  VpnBlock,
} from "../styles";

const UserInfoHeader = ({
  avatarImg,
  firstName,
  lastName,
  middleNames,
  extraText,
  sticky,
  progress,
  account,
  size,
}) => {
  const [businessCardUrl, setBusinessCardUrl] = useState(null);
  const urlPath = account?.microsite?.formsPage?.urlPath;
  const {
    branchProfile,
    preneurType,
    profile: { isVerified },
    smartLinks = [],
  } = account || {};

  const hasBranchProfile = !!branchProfile;

  useEffect(() => {
    const businessLink = smartLinks.find(
      (link) => link.type === "DIGITAL_BUSINESS_CARD"
    );
    if (businessLink) {
      setBusinessCardUrl(businessLink.url);
    }
  }, [smartLinks]);

  return (
    <HeroWrapper sticky={sticky} progress={progress} size={size}>
      <Avatar size={size} user={{ firstName, lastName }} imageUrl={avatarImg} />

      <SectionWrapper padded progress={progress} size={size}>
        <LPTitle nomargin large upcase letterspacing>
          {firstName && firstName.length > 0 && (
            <span className={lastName ? "thin" : "firstName"}>{firstName}</span>
          )}
          {middleNames &&
            middleNames.map((middleName) => (
              <span key={middleName} className="lastName">
                {middleName}
              </span>
            ))}
          {lastName && lastName.length > 0 && (
            <span className="lastName">{lastName}</span>
          )}
          {isVerified && (
            <img src="/images/verifiedCheckIcon.png" alt="blue checked icon" />
          )}
        </LPTitle>
        {hasBranchProfile && <LPText nomargin>{branchProfile.shortBio}</LPText>}
        {!hasBranchProfile && preneurType && preneurType !== "" && (
          <PreneurTypeText>
            <span>{preneurType}</span>
            .PRENEUR
          </PreneurTypeText>
        )}

        {extraText !== "" && <LPText w400>{extraText}</LPText>}

        {urlPath && (
          <VpnBlock>
            {businessCardUrl !== null && (
              <Link href={businessCardUrl} target="__blank">
                <Button brand>Save my contact</Button>
              </Link>
            )}
            <Link href={urlPath}>
              <Button outlined>Get in touch</Button>
            </Link>
          </VpnBlock>
        )}
      </SectionWrapper>
    </HeroWrapper>
  );
};

UserInfoHeader.defaultProps = {
  avatarImg: "",
  firstName: "",
  lastName: "",
  middleNames: [],
  extraText: "",
  sticky: false,
  progress: 0,
  account: {},
  size: "large",
};

UserInfoHeader.propTypes = {
  account: PropTypes.shape(),
  avatarImg: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  middleNames: PropTypes.arrayOf(PropTypes.string),
  extraText: PropTypes.string,
  sticky: PropTypes.bool,
  progress: PropTypes.number,
  size: PropTypes.string,
};

export default memo(UserInfoHeader);
