import { ALLOWED_VIDEO_EXT } from "../constants/siteData";

export const getTextValue = (text) => text;

export const stripTrailingSlash = (str) => {
  return str.endsWith("/") ? str.slice(0, -1) : str;
};

export const checkHttpsUrl = (path = "") => {
  return path.startsWith("http") ? path : `https://${path}`;
};

export const getLocalTextValue = (text, lang) => {
  const localization = lang || "en";
  const value = text && JSON.parse(text.values);
  return value ? value[localization] : "";
};

export const isVideoType = (path = "") => {
  const array = path.split(".") || [];
  return ALLOWED_VIDEO_EXT.includes(array.slice(-1).pop());
};

export const httpsUrl = (path = "") => path?.replace("http://", "https://");
