import styled, { css } from "styled-components";
import { grey } from "@material-ui/core/colors";

export const Wrapper = styled.div`
  --prime-gradient: linear-gradient(
    var(--brand-color),
    var(--brand-color-light)
  );

  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  align-items: center;
  border-bottom: 1px solid var(--border-dark-color);
  color: var(--text-color);
  margin: 0;
  padding: 0.75rem;

  ${(props) =>
    props.prime &&
    css`
      position: sticky;
      top: 150px;
      z-index: 9;
      background: var(--prime-gradient);
      color: var(--text-light-color);
      border-bottom: none;
    `};

  ${(props) =>
    props.active &&
    css`
      background: var(--prime-gradient);
      color: var(--text-light-color);
    `};

  ${(props) =>
    props.disabled &&
    css`
      background: var(--disabled-gradient);
      color: var(--text-light-color);
      min-height: 65px;
      box-sizing: border-box;
    `};

  .MuiCheckbox-colorSecondary.Mui-disabled {
    color: ${grey[300]};
  }
`;

export const IconWrapper = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;

  ${(props) =>
    props.imageUrl &&
    css`
      background-image: url("${props.imageUrl}");
      background-size: cover;
      background-position: center;
    `}
`;

export const ContentWrapper = styled.div`
  padding-left: 1rem;
  padding-right: 0.5rem;
  display: flex;
  flex-flow: column nowrap;
`;

export const LinkTitle = styled.h3`
  font-weight: 800;
  font-size: 1em;
  margin: 0;
  max-width: 15rem;
  overflow-wrap: break-word;
`;

export const LinkSubtitle = styled.p`
  margin: 0;
  font-size: 0.9em;
`;

export const ActionWrapper = styled.div`
  display: flex;
  margin-left: auto;
`;

export const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row nowrap;

  cursor: pointer;
  border: 1px solid var(--button-border-dark-color);
  border-radius: 0.25rem;
  padding: 0.25rem 0.75rem;
  min-width: 80px;

  font-weight: 600;
  font-size: 0.65rem;
  text-transform: uppercase;
  white-space: nowrap;

  ${(props) =>
    props.brand &&
    css`
      background-color: var(--brand-color);
      color: var(--text-light-color);
      border-color: var(--brand-color-light);
    `}

  ${(props) =>
    props.prime &&
    css`
      background-color: var(--text-light-color);
      color: var(--button-border-color);
      border-color: var(--text-light-color);
    `}

  ${(props) =>
    props.outlined &&
    css`
      background-color: transparent;
      color: var(--outline-button-color);
      border-color: var(--outline-button-color);
    `}

  ${(props) =>
    props.isText &&
    css`
      background: var(--prime-gradient);
      color: var(--text-light-color);
      border-color: white;
    `}

    @media screen and (max-width: 320px) {
    width: 4rem;
    padding: 0.4rem 0.05rem;
    text-align: center;
  }
`;
