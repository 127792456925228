import styled, { css } from "styled-components";
import { grey, green, red, orange } from "@material-ui/core/colors";
import device from "../constants/mediaBreakPoints";

export const AppWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;

  ${(props) =>
    props.brandColor &&
    css`
      --brand-color: ${props.brandColor};
      --brand-color-light: ${props.brandColor};
    `}
`;

export const ScrollingContent = styled.div`
  display: flex;
  align-self: stretch;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  padding: 1em 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  ${(props) =>
    props.fullheight &&
    css`
      min-height: 100vh;
    `}
  ${(props) =>
    props.nopadding &&
    css`
      padding: 0;
    `}
`;

export const PageWrapper = styled.div`
  background-color: var(--background-color);
  display: flex;
  flex: 1 0 auto;
  flex-flow: column nowrap;
  ${(props) =>
    props.scrollable &&
    css`
      height: calc(100%);
      overflow: auto;
    `}
  ${(props) =>
    props.dark &&
    css`
      --background-color: #000000;
      --background-color-rgb: 0, 0, 0;
      --background-secondary-color: ${grey[900]};
      --border-color: ${grey[900]};
      --border-dark-color: ${grey[800]};
      --button-text-color: ${grey[800]};
      --button-border-color: ${grey[800]};
      --button-border-dark-color: #fff;
      --card-bg-color: ${grey[900]};
      --event-item-bg-color: ${grey[800]};
      --text-color: #fff;
      --text-secondary-color: ${grey[400]};
      --outline-button-color: ${grey[200]};
    `}
`;

export const LPTitle = styled.p`
  color: var(--text-color);
  font-weight: 800;
  font-size: ${(props) => props.fontSize || '1.2em'};
  letter-spacing: 0.075em;
  line-height: 1.4;
  margin: 0 0 0.5rem 0;
  > span {
    margin-right: 0.5rem;
  }
  .thin {
    margin-right: 0;
    font-weight: 200;
  }
  .lastName {
    margin-right: 0;
    margin-left: 0.5rem;
  }
  .firstName {
    margin-right: 0;
    margin-left: 0;
  }
  ${(props) =>
    props.nomargin &&
    css`
      margin: 0;
    `}

  ${(props) =>
    props.letterspacing &&
    css`
      letter-spacing: 0;
    `}
  ${(props) =>
    props.badge &&
    css`
      margin: 0 0 0.2rem 1.6rem;
    `}

  ${(props) =>
    props.small &&
    css`
      font-size: 0.9em;
    `}

   ${(props) =>
    props.upcase &&
    css`
      text-transform: uppercase;
    `}

   img {
    height: 16px;
    margin: 0 0 0 0.5rem;
    width: 16px;
  }
`;

export const VpnBlock = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 0;
`;

export const PreneurTypeText = styled.p`
  color: var(--text-color);
  font-weight: 400;
  font-size: 1em;
  margin: 0;
  margin-top: 0.3rem;
  line-height: 0;
  padding-top: 0.45rem;
  padding-bottom: 0.6rem;
  span {
    line-height: 0;
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: lowercase;
    font-family: ganache;
  }
`;
export const LPText = styled.p`
  color: var(--text-color);
  font-weight: 400;
  font-size: 1em;
  line-height: 1.6;
  margin: 0;
  ${(props) =>
    props.vpn &&
    css`
      font-size: 1.1rem;
      font-weight: 800;
      color: var(--text-color);
      @media screen and (max-width: 320px) {
        font-size: 0.8rem;
      }
    `}

  ${(props) =>
    props.w200 &&
    css`
      font-weight: 400;
      font-size: 0.9rem;
      line-height: 0;
      padding: 0.3rem 0 0.8rem 0;
    `}
  ${(props) =>
    props.w600 &&
    css`
      font-weight: 600;
    `}
  ${(props) =>
    props.w800 &&
    css`
      font-weight: 800;
    `}
  ${(props) =>
    props.mb1 &&
    css`
      margin-bottom: 1em;
    `}
  ${(props) =>
    props.mb2 &&
    css`
      margin-bottom: 2em;
    `}
  ${(props) =>
    props.m05 &&
    css`
      margin: 0.5rem;
    `}
  ${(props) =>
    props.marginleft &&
    css`
      margin-left: auto;
    `}
  ${(props) =>
    props.marginright &&
    css`
      margin-right: auto;
    `}
  ${(props) =>
    props.lowercase &&
    css`
      text-transform: lowercase;
    `}
  ${(props) =>
    props.nomargin &&
    css`
      margin-bottom: 0;
    `}
  ${(props) =>
    props.padded &&
    css`
      padding: 0.5em 1em;
    `}
  ${(props) =>
    props.centered &&
    css`
      text-align: center;
    `};
  ${(props) =>
    props.small &&
    css`
      font-size: 0.8em;
    `};
  ${(props) =>
    props.xsmall &&
    css`
      font-size: 0.6em;
    `};
  ${(props) =>
    props.description &&
    css`
      font-size: 0.8em;
      margin-bottom: 0.5em;
      color: ${grey[400]};
    `};
  ${(props) =>
    props.error &&
    css`
      background-color: ${props.clear ? "transparent" : red[600]};
      border-radius: 0.25em;
      color: ${props.clear ? red[600] : grey[100]};
      padding: 0.5em 1em;
    `}
  ${(props) =>
    props.warning &&
    css`
      background-color: ${props.clear ? "transparent" : orange[600]};
      border-radius: 0.25em;
      color: ${props.clear ? orange[600] : grey[100]};
      padding: 0.5em 1em;
    `}
  ${(props) =>
    props.success &&
    css`
      background-color: ${props.clear ? "transparent" : green[600]};
      border-radius: 0.25em;
      color: ${props.clear ? green[600] : grey[100]};
      padding: 0.5em 1em;
    `}
  ${(props) =>
    props.active &&
    css`
      color: var(--brand-color);
    `}
`;

export const LPImageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 60%;
  padding: 0 2rem;
  img {
    border: 1px solid ${grey[50]};
    object-fit: contain;
    width: 100%;
  }
  ${(props) =>
    props.small &&
    css`
      flex: 0 0 auto;
      height: 210px;
      margin: 1em 0;
      padding: 0;
      overflow: hidden;
      img {
        object-fit: cover;
      }
    `}
  @media screen and ${device.tablet} {
    order: 0;
    padding: 0 0 2rem;
  }
  @media screen and ${device.mobileL} {
    order: 0;
    padding: 0;
    height: auto;
  }
`;

export const NavBar = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 80px 1fr 80px;
  grid-template-rows: 1fr;
  padding: 1em;
  a {
    display: flex;
    align-items: center;
  }
  .sc_logo {
    height: 18px;
  }
`;

export const CardArea = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex: 1 1 auto;
  overflow-x: hidden;
  background-color: var(--background-color);
  border: 1px solid var(--border-color);
  width: 100%;
  max-width: 500px;
  margin: auto;
  box-shadow: none;
  transition: box-shadow 0.3s ease-out;
  ${(props) =>
    props.noBorder &&
    css`
      border: none;
    `};
  ${(props) =>
    props.padding &&
    css`
      padding: 1em;
    `}
  &:hover {
    box-shadow: 0 2em 2em -1em var(--border-dark-color);
  }
  @media screen and ${device.mobileL} {
    margin: 0;
    width: auto;
  }
`;

export const CardBody = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  color: inherit;
  position: relative;
  ${(props) =>
    props.noPadding &&
    css`
      padding: 0;
    `};
  ${(props) =>
    props.centered &&
    css`
      align-items: center;
      justify-content: center;
      > p {
        text-align: center;
      }
    `}
  @media screen and ${device.mobileL} {
    max-width: 100%;
    width: auto;
    &:hover {
      box-shadow: none;
    }
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
  min-height: 530px;
`;

export const CardFooter = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-self: stretch;
  padding: 0.5em 0 0;
  z-index: 1;
  color: ${grey[500]};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;

  .MuiButton-root {
    font-size: 0.8em !important;
  }

  @media screen and ${device.mobileL} {
    background-color: white;
    flex: 0 0 auto;
    position: sticky;
    bottom: 0;
    user-select: none;
    padding: 0;
  }
`;

export const CardTextArea = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-self: stretch;
  color: ${grey[500]};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.4;
  ${(props) =>
    props.padded &&
    css`
      padding: 0.5em 1em;
    `}
`;

export const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 1em;
  align-items: center;
  padding: 1em;
  position: relative;
  ${(props) =>
    props.hasCoverMedia &&
    css`
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      text-align: center;
      z-index: 1;
      padding-top: calc(40px + 1em);
    `}
`;

export const BodyWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 0 0 auto;
  padding: 0 1em;
  background-color: var(--background-color);
  z-index: 1;
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;
`;

export const SectionWrapper = styled.div`
  width: calc(100% - 64px - 2em);
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  align-self: flex-start;
  position: absolute;
  left: 100px;
  top: 50%;
  transform: ${(props) =>
    `translateY(${-60 + (40 - (40 * props.progress) / 100)}%)`};

  ${(props) =>
    props.size === "base" &&
    css`
      left: 65px;
      font-size: 0.7em;
    `}

  ${(props) =>
    props.size === "medium" &&
    css`
      left: 50px;
      font-size: 0.7em;
    `}

  ${(props) =>
    props.size === "extraLarge" &&
    css`
      left: 120px;
      font-size: 1.2em;
    `}

  ${(props) =>
    props.bordered &&
    css`
      border-bottom: 1px solid var(--border-color);
      &:first-child {
        border-top: 1px solid var(--border-color);
      }
    `}
  ${(props) =>
    props.padded &&
    css`
      padding: 0 1em;
    `}
    ${(props) =>
    props.centered &&
    css`
      align-items: center;
      justify-content: center;
    `};
`;

export const HeroWrapper = styled.div.attrs((props) =>
  props.sticky
    ? {
        style: {
          backgroundColor: `rgba(var(--background-color-rgb),
        ${props.progress / 100})`,
          marginTop: `${-45 + (45 * props.progress) / 100}px`,
        },
      }
    : {}
)`
  display: flex;
  align-items: stretch;
  align-self: stretch;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  padding: 0 1em 1em;
  position: relative;

  ${(props) =>
    props.sticky &&
    css`
      position: sticky;
      top: 0;
      padding: 1em 1em 2em;
      z-index: 9;
    `};
`;
